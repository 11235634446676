
.work-experience {
  max-width: 450px;
  margin: 0 auto;
}

.work-experience__title {
  font-size: 2rem;
  font-weight: 100;
}

.work-experience__inner {
  display: flex;
  flex-direction: column;

}

.work-experience__item:first-child {
  margin-top: 0;
}

.work-experience__item {
  margin-top: 1rem;
}

.work-experience__item-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.work-experience__item-head-top {
  display: flex;
  align-items: baseline;
}

.work-experience__item-head-bottom {
  display: flex;
  align-items: baseline;
}

.work-experience__period {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 12px;
}

.work-experience__company-name {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 12px;
}

.work-experience__role {
  font-weight: 300;
}
.work-experience__item-body {

}

.work-experience__item-body > li {
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; 
	padding-top: 25px;
	height: 0;
  overflow: hidden;
  margin-bottom: 12px;
  margin-top: 5px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}