.App {
  text-align: center;
  padding-top: 32px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}


.Name {
  display: block;
}