.technologies {
  max-width: 450px;
  margin: 0 auto;
}

.technologies__title {
  font-size: 2rem;
  font-weight: 100;
}

.technologies__body {

}

.technologies__item {
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}