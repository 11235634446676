
.header {
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  border-bottom: solid 1px #ddd;
  padding-bottom: 2rem;
}

.header__img {
  height: 200px;
  border-radius: 12px;
}

.header__right {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-left: 2rem;
}

.header__name {
  font-size: 2rem;
  font-weight: 100;
  margin: 0;
}

.header__role {
  font-weight: 500;
  font-size: 18px;
}

.header__email {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}

.header__email:hover {
  color: rgba(0, 0, 0, 1);
}