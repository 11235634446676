body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
    display: block;
    margin-top: 3rem;
    font-size: 12px;
}

ul {
  padding-left: 1rem;
}
.App {
  text-align: center;
  padding-top: 32px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
}


.Name {
  display: block;
}

.header {
  display: -webkit-flex;
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  border-bottom: solid 1px #ddd;
  padding-bottom: 2rem;
}

.header__img {
  height: 200px;
  border-radius: 12px;
}

.header__right {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  padding-left: 2rem;
}

.header__name {
  font-size: 2rem;
  font-weight: 100;
  margin: 0;
}

.header__role {
  font-weight: 500;
  font-size: 18px;
}

.header__email {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  text-decoration: none;
}

.header__email:hover {
  color: rgba(0, 0, 0, 1);
}

.work-experience {
  max-width: 450px;
  margin: 0 auto;
}

.work-experience__title {
  font-size: 2rem;
  font-weight: 100;
}

.work-experience__inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;

}

.work-experience__item:first-child {
  margin-top: 0;
}

.work-experience__item {
  margin-top: 1rem;
}

.work-experience__item-head {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.work-experience__item-head-top {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.work-experience__item-head-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
          align-items: baseline;
}

.work-experience__period {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 12px;
}

.work-experience__company-name {
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 12px;
}

.work-experience__role {
  font-weight: 300;
}
.work-experience__item-body {

}

.work-experience__item-body > li {
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; 
	padding-top: 25px;
	height: 0;
  overflow: hidden;
  margin-bottom: 12px;
  margin-top: 5px;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.technologies {
  max-width: 450px;
  margin: 0 auto;
}

.technologies__title {
  font-size: 2rem;
  font-weight: 100;
}

.technologies__body {

}

.technologies__item {
  text-align: left;
  margin-top: 5px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}
